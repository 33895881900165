body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#root,body{
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header a{
  text-decoration: none;
  color: #021260;
}

.page{
  height: 100%;
  padding-bottom: 2em;
}


.sizestabsColumn{
}



.product_varations li a{
  text-decoration: none;
  color: #021260;
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.2em 0.5em;
  display: inline-block;
  margin-bottom: 0.5em;
  margin-left: 0;
  float: left;
  margin-right: 1em;
}

li.nav-item button{
  color: #01aef3;
}

.product_varations li a:hover{
  border: 1px solid #021260;
}
.product_varations li a.selected{
  background: #021260;
  color: white;
  cursor: default;
  border: 1px solid #ddd;
}

.disclaimer{
  padding: 0.5em 1em;
  color: white;
  font-size: 0.8em;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}


@media all and (min-width:0px) and (max-width: 768px) {
  /* put your css styles in here */

  .pricetag{
    text-align: right;
  }
}

.product_varations ul{
  list-style: none;
  padding-left: 0.5em;
}

.productId{
  border-top: 1px solid #ddd;
  margin-top: 1em;
  padding-top: 0.5em;
}

.productId span{
  background: white;
  margin-left: 0.5em;
  display: inline-block;
  margin-top: 0.5em;
  border: 1px solid #ddd;
  color: #1f66a4 !important;
  font-weight: 700;
  letter-spacing: 2px;
  padding: .2em .5em;
}

.footer{
  height: 100%;
  text-align: center;
  background: #01aef3;
}
.footer li {
  display: inline;
  margin-left: 1em;
}
.footer ul {
  margin: 0;
}
.footer a{
  text-decoration: none;
  color: white;
}
.footer a:hover{
  text-decoration: none;
  color: #f1f1f1;
}

.iconbox{
  display: inline-block;
  float: left;
  width: auto !important;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  min-width: 55px;
  padding: 0.2em;
  font-weight: bold;
}


table.styledTable{
  padding: 0em;
  margin: 0;
  border-collapse:separate;
  border-spacing: 0 0.8em !important;
}

table.styledTable td,
table.styledTable th{
  padding: 0em 0em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 1em;
}



table.styledTable th{
  background: #db231f;
  color: white;
}

table.styledTable .rowTitle{
  text-decoration: none !important;
  font-size: 1.2em;
}
table.styledTable  a{
  color: #021260;
  text-decoration: none !important;
}
table.styledTable  a:hover{
  color: #1f66a4 !important;
  text-decoration: none !important;
}

table.styledTable .courseBadge{
  background: #01aef3 !important;
}
table.styledTable .instructorBadge{
  background: #021260 !important;
  border: 1px solid #ddd;
}
table.styledTable .traineeBadge{
  background: #fff !important;
  color: #021260;
  border: 1px solid #ddd;
  font-weight: normal !important;
}

table.styledTable tr:nth-child(even) td {
  border-left: 5px solid #555;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
table.styledTable tr td{
  background: #f1f1f1;
  border-left: 5px solid #021260;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;

}



.multiselect-container .singleChip{
  background: none;
  color: #021260;
  padding:0;
  font-size: 1em;
  padding-left: 0.5em;
}
.multiselect-container {
  background: white;
  border-radius: 0.5em;
  border: 1px solid #ddd;
}
.multiselect-container .searchWrapper{
  border: none;
  border-radius: 0.5em;
  border: 1px solid #ddd;
}
.singleChip .closeIcon{
  display: none;
}


.evaluationMatrix .title{
  padding: 0.2em;
}
.evaluationMatrix .changed .title{
  background: #0d1520;
  padding: 0.2em;
  border-radius: 0.2em;
  color: white;
}

.evaluationMatrix .changed .value{
  background: #ccc !important;
}