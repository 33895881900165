.WorksheetImageDropzone{

}

.WorksheetImageDropzone .dropzone-container{
    width: 100%;
    height: auto;
}
.WorksheetImageDropzone .project-file-preview{
    margin-bottom: 0.3em;
    margin-top: 0.5em;
    width: 100%;
}
.WorksheetImageDropzone .project-file-preview img{
    padding: 0.2em;
    border: 1px solid white;
    display: inline-block;
    width: 100%;
    height: auto;
    border-radius: 1em;
}
.WorksheetImageDropzone .project-file-preview-simple img{
    padding: 0.2em;
    border: 1px solid white;
    display: inline-block;
    width: 100%;
    height: auto;
    border-radius: 1em;
}


.WorksheetImageDropzone .imageFileWrapper.deactivated img{
    border: 2px solid #641723;
    opacity: 0.5;
}
