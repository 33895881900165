.components .componentsDropZone{
    background: none;
    padding: 0.2em !important;
    border: 0px dashed #ccc;

}

.components.empty .componentsDropZone{
    background: none;
    padding: 0.2em !important;
    border: 2px dashed #ccc;
    min-height: 5em;
    margin-bottom: 1em;
}
.components .componentsDropZone.isDraggingOver,
.components.empty .componentsDropZone.isDraggingOver{
    border: 2px dashed blue;

}

.sectionDraggable{
    padding: 1em;
}

.sectionDraggable.isDragging{
    border: 1px solid #ddd;
}