.evaluationPopup .modal-dialog{
    width: 35em;
    max-width: 96% !important;
}

.evaluationPopup .modal-body{
    background: #ddd;
    color: #021260;
}

.evaluationPopup .modal-body h5{
    color: #021260;
}

.evaluationPopup .modal-body .multiSelectContainer  {
    color: #021260;
    background: #f1f1f1;
    border: none;
    margin-bottom: 0.5em;
}
.evaluationPopup .modal-body .multiSelectContainer .singleChip{
    color: #021260;
}



.evaluationPopup .modal-header{
    background:#f1f1f1;
}

.evaluationPopup .modal-header .modal-title h2{
    color: #021260;
}