.ProjectTaskDescriptionPrintView .topic{
    color: #333;
}



.ProjectTaskDescriptionPrintView .alert {
    border: none;
    padding-bottom: 1em;
}

.taskDescription .sectionAccordionCardBody{
    padding: 0em 1em;
}
.taskDescription  h2{
    padding: 0.5em 1em;
    padding-bottom: 0;
}