.WorksheetsListPageForTrainee .pageContent{
    padding: 0;
}

.WorksheetsListPageForTrainee .worksheetStatus{
    background: #ddd;
    width: 100%;
    display: block;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    padding: 0.5em;
}

.ltb-view .pageTopTitle{
    background: #fff;
    padding: 1em;
}

.ltb-view{
    background: #fff;
}

.ltb-view .pageContent, .ltb-view .card-body{
    background: #f1f1f1;
}

.ltb-view  .CenteredMessageWrapper{
    color: #021260;
}
