.LoadingScreen{
    text-align: center;
}

.loadingWraper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    min-height: calc(10vh -  2em);
    color: #444;
}

.loadingWraper .loadingImage{
    height: 10em;
}