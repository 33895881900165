.WorksheetPrintPage {
    background: #fff;
}

.WorksheetPrintPage .pdf-wrapper{
    padding: 1em;
    margin: 0px auto;
}

.WorksheetPrintPage .buttonPanel{
    max-width: 1024px !important;
    margin: 0px auto;
    padding-top: 0.5em;
}
.WorksheetPrintPage .buttonPanel button{
    margin-right: 1em;
}

.react-pdf__Page__canvas{
    padding:0em;
    background: #fff;
    border-radius: 0.3em;
    border: 2px solid #f1f1f1;
    margin-bottom: 3em;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.react-pdf__Document,.react-pdf__Page__canvas,.react-pdf__Page__canvas,
.react-pdf__Page__textContent,.pdf-file,.react-pdf__Document{

}



@media all and (max-width:768px) {
    .WorksheetPrintPage .pdf-wrapper{
        padding:0.5em;
    }

}

.pdfPageTitle  {
    background: #df271b;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 1em;
    position: absolute;
    right: 0.5em;
    top:0.5em;
    z-index: 100;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}