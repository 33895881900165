.SingleCoursePage .profileBlock{
    background: #021986;
    color: white;
    padding-bottom: 1em;
}

.SingleCoursePage  .pageContent{
    padding: 0em 0em;
    padding-bottom: 2em;
    padding-top: 1em;
    height: 100%;
    min-height: 30vh;
}

.topicKachel .topicKachelInner h3{
    color: #0d1520;
    font-size: 1.3em;
}

.topicKachel .topicId{
    display: inline-block;
    font-weight: bold;
    padding: 0.5em;
    padding-bottom: 0em;
}


.topicKachel{
    background: white;
    margin-bottom: 1em;
    border-radius: 0.5em;
    transition: transform .2s; /* Animation */
    border: 5px solid #fff;
    border-radius: 1em;
}



.topicKachelOuter:hover .topicKachel{
    background: #01aef3 !important;
    border-radius: 1em;
    border: 5px solid #09a8e8;
    cursor: pointer;
    color: white;
}
.topicKachelOuter .bgimage{
    border-top-left-radius: 0.7em;
    border-top-right-radius: 0.7em;
}





.topicKachelInner .workingsheetBadges{
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
}
.topicKachel .topicImage{
    width: 100%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}


@media all and (min-width:768px) {
    .topicKachelOuter:hover .topicKachel{
        transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
}

.topicKachel .topicKachelOuter{
    border-radius: 0.5em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.topicKachelOuter:hover .topicId{
    color: #021260 !important;
}

.topicKachel .topicSticker{
    position: absolute;
    margin-left: -0.5em;
    margin-top:1.5em;
    background: #df271b;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: white;
    padding: 0.2em 0.5em;
    padding-left: 1.5em;
    padding-right: 1em;
}
.topicKachel .topicStickerRight{
    position: absolute;
    margin-left: -0.5em;
    margin-top:3.8em;
    background: #021260;
    color: white;
    padding: 0.2em 0.5em;
    padding-left: 1.5em;
    padding-right: 1em;
}

.SingleCoursePage .worksheetListRow .SingleSelectInputFormField .singleSelect{
    color:red;
    background: none;
    border: none;
}
.SingleCoursePage .worksheetListRow .SingleSelectInputFormField .singleChip {
    color:#021260;
}

.SingleCoursePage .worksheetListRow .SingleSelectInputFormField .singleSelect{
    color:red;
    background: none;
    border: none;
}

.SingleCoursePage .worksheetListRow .SingleSelectInputFormField .multiselect-container{
    background: none;
    border: 1px solid #0d2847;
    color: white;
}
.SingleCoursePage .worksheetListRow .SingleSelectInputFormField .multiselect-container span{
    color: white;
}

.SingleCoursePage .worksheetListRow .empty .SingleSelectInputFormField .multiselect-container{
    background: #641723;
}
