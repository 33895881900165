.CenteredMessage{
    text-align: center;

}

.CenteredMessageWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2em + 2vmin);
    min-height: calc(50vh -  2em);
    background-image: linear-gradient(180deg, #216093 0%, #FFFFFF 100%);
}

