.kwFilter{
    padding: 0.5em 1em 0.5em 1em;
    background: #fff;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.kwFilter h3{
    margin:0;
}
.kwFilter button {
    color: #021260;
    background: white;
    border-color: #01aef3;
}
.kwFilter button:hover{
    color: white;
    background: #01aef3;
}