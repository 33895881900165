

.topicRow{
    margin-bottom: 1em;
    background: #f5f5f5;
    border-radius: 1em;
    padding: 0.2em 1em;
    vertical-align: middle;
}

a.professionFilter.selected{
    color:white;
    background:#01aef3;
}

.connectedTopicWithoutTemplate .cardHeaderWrapper{
    background: #ddd !important;
}

ul.selected-topics-list li, ul.topicsFilter li{
    display: inline-block;;
    background: #0d1520;
    margin-right: 0.2em;
    margin-bottom: 0.2em;
    padding: 0.2em 0.8em;
    color: white;
    border-radius: 1em;
}
ul.selected-topics-list, ul.topicsFilter{
    margin: 0;
    padding: 0;
}

a.professionFilter{
    color:#333;
    background:#f1f1f1;
    padding:0.2em 0.5em;
    min-width: 2.5em;
    text-align: center;
    border-radius: 1em;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 1em;
    margin-right: 0.5em;
}
a.professionFilter:hover{
    background:#ccc;
}

.topic{
    font-weight: bold;
}

.topicKachel{
    background: #aaa;
    color: #f1f1f1;
}
.topicKachel h3{
    min-height: 4em;
    display: table;
    width: 100%;
    margin-bottom: 0;
    text-decoration: none;
}



.topicKachel h3 span.topicId{
    padding: 0em 0.5em;
    background: #fff;
    display: table-cell;
    text-decoration: none;
    text-underline: none;
    text-align: center;
    vertical-align: middle !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
}

.TopicsListPageForInstructor .topicImage{
    width: 100%;
    border-radius: 1em;
    margin-right: 0.5em;
}

.TopicsListPageForInstructor .topicCoverImage
{
   width: 100%;
    border-radius: 1em;
    margin-right: 0.5em;
}

.topicRow:hover{
}

.noTemplate{
    background: #01aef3;
}

.professionTitle{
    color: white;
    margin-top: 0.5em;
}

.connected,
.unconnected{
    display: inline-block;
    margin-bottom: 0.2em;
}
.unconnected{
    background: #641723;
}
.noTemplate{
    background: #3b9eff;
}

.connected:hover,
.unconnected:hover{
    background: #0d6efd;
    cursor: pointer;
}


.TopicsListPageForInstructor .multiselect-container .singleChip{
    color: white;
    font-size: 1.3em;
    padding: 0em !important;
    width: 95% !important;
    overflow-x: hidden;
    border-radius: 0;
}

.TopicsListPageForInstructor .multiselect-container .searchWrapper {
    padding: 1em !important;
    border:none;
    background: #1f66a4;
    margin-bottom: 1em;
}

.TopicsListPageForInstructor #multiselectContainerReact{
    background: #021260 !important;
    border:none;
    color: white !important;
}
.TopicsListPageForInstructor #multiselectContainerReact .searchBox {
    color: white !important;
    width: 7em !important;
    visibility: hidden;
    background: #641723;
}

