
.multiselect-container .singleChip{
    background: none;
    color: #021260;
    padding:0;
    font-size: 1em;
    padding-left: 0.5em;
}
.multiselect-container {
    background: white;
    border-radius: 0.5em;
    border: 1px solid #ddd;
}
.multiselect-container .searchWrapper{
    border: none;
    border-radius: 0.5em;
    border: 1px solid #ddd;
}

.projectName{
    border: 1px solid #ddd;
    border-radius: 1em;
    margin-right: 0.3em;
    padding: 0.2em 0.5em;
}
