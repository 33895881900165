.SingleTraineePage .pageContent{
    padding: 0.5em;
}

.logoutContainer .logout{
    background: #01aef3;
    float: right;
}
.logoutContainer{
    margin-bottom: 1em;
}