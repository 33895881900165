.worksheet{
    padding: 1em;
    max-width: 100%;
    margin: 0px auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}

.editorButton{
    display: inline-block;
    background: #641723;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 1em;
    color: white;
}
.editorButton:hover{
    cursor: pointer;
}

.worksheetComponentsEditor {
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
    background: #1f66a4;
    color: white;
    position: fixed;
    right: 0;
    top:0.5em;
    z-index: 5;
    margin-bottom: 3em;
    max-width: 80%;
    right: 0.5em;
    height: calc(100% - 6em);
}

.worksheetComponents_inner{
    width: 100%;
    height: 100%;
}

.worksheetComponents h4,
.worksheetComponents h2{
    color: white;
}


.worksheet-body{
    background: none;
    padding: 0em;
    width: 100%;
    margin: 0px;
    border-radius: 1em !important;
}

.worksheet-body .sectionDraggable{
    margin-bottom: 1em;
    background: #fefefe;
    padding-bottom: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}