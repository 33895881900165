.MyCoursesListPage  .pageContent{
    padding: 0em 0em;
    padding-bottom: 2em;
    padding-top: 0em;
    height: 100%;
    min-height: 30vh;
}

.MyCoursesListPage .pradleAccordion .card-body{
    background: #fff;
}

.MyCoursesListPage .finished_course.bg-primary,
.MyCoursesListPage .running_course.bg-primary,
.MyCoursesListPage .comming_course.bg-primary{
    margin-left: 0.5em;
    margin-bottom: 0.2em;
    display: inline-block;
}
.MyCoursesListPage .finished_course.bg-primary{
    background: #0d6efd !important;
}
.MyCoursesListPage .running_course.bg-primary{
    background: #111111 !important;
}
.MyCoursesListPage .comming_course.bg-primary{
    background: #021260 !important;
}

.MyCoursesListPage .pradleAccordion .card-body .topic,
.MyCoursesListPage .pradleAccordion .card-body .trainee,
.MyCoursesListPage .pradleAccordion .card-body .instructor{
    color: #021260 !important;
    padding: 0.1em 0.5em;
    margin-right: 0.5em;
    border: 1px solid #021260;
    display: inline-block;
    border-radius: 1em;
    margin-bottom: 0.2em;
    background: #f1f1f1;
}

.MyCoursesListPage .pradleAccordion .card-body .topic:hover,
.MyCoursesListPage .pradleAccordion .card-body .trainee:hover,
.MyCoursesListPage .pradleAccordion .card-body .instructor:hover{
    cursor: pointer;
    background: #0220ac;
    color: white !important;
    border: 1px solid #0220ac;
}


.MyCoursesListPage .pradleAccordion .card-body .novalue{
    color: #021260 !important;
    margin-right: 0.5em;
    display: inline-block;
    font-weight: bold;
    border-radius: 1em;
    margin-bottom: 0.2em;
}


.MyCoursesListPage .pradleAccordion .card-body .svg-inline--fa{
    color: #021260;
}


.MyCoursesListPage .pradleAccordion .card-header .instructor{
    color: #fff !important;
    background: #0327d2;
    padding: 0.01em 0.5em;
    font-size: 0.8em;
    margin-right: 0.3em;
    display: inline-block;
    border-radius: 1em;
    margin-bottom: 0.2em;
}
