.account-header{
    padding: 0em 1em;
}

.account-header .userType{
    margin:1px;
    display: inline-block;
    padding: 0.2em 0em;
    border-radius: 1em;
    margin-right: 1em;
    width: auto;
    text-align: right;
}
.account-header .email{
    font-weight: bold;
    display: inline-block;
    font-size: 0.8em;
    margin-top: 0.5em;
}
.account-header .active button{
    background: #fff;
    color: #021260;
    margin-bottom: 0px;
}
.account-header button:hover{

    margin-bottom: 0px;

}
.account-header button{
    background: none;
    border:none;
    margin-right:0.5em;
    border-radius: 0;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    margin-top:0.5em;
    color: #888;
}

.profileBlock h5{
    margin-top: 0;
    font-size: 1em;
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

.profileBlock{
    background: #f5f5f5;
    margin-bottom: 1em;
    border-radius: 1em;
    padding: 0.2em 0.8em;
}

.notifications{
    max-width: calc(80vw - 20px);

    position: relative;
    margin: calc(3vh - 18px) auto;
}
