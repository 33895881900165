span.userType{

}

.LogoutButtonComponent a{
    text-decoration: none;
}


.user-menu .active button{
    background: #f5f5f5;
    color:#444;
}


.user-menu .active #dropdown-basic span{
    color:#444;
}
.user-menu button{
    border: none;
    background: #f5f5f5;
    color:#444 !important;
}
.user-menu button:hover,
.user-menu button:active,
.user-menu button:target,
.user-menu button:active,
.user-menu .dropdown:hover,
.user-menu .dropdown:active,
.user-menu .dropdown:target,
.user-menu .dropdown:active{
    border: none;
    color:#0d1520 !important;
}



.user-menu .btn {
    background: none !important;
}

.user-menu{
    padding:0.5em 1em;
    background: #f5f5f5;
}
.user-menu a{
    margin-right: 0.5em;
}

.user-menu a{
    text-decoration: none;
    color: #444;
}
.user-menu a.active{
    text-decoration: none;
    color: #005ca1;
}
.user-menu a.hover{
    text-decoration: none;
    color: #005ca1;
}

.menuUserLoginName{
    margin-right: 0.5em;
}
.LogoutButtonComponent .menuUserLoginName:hover,
span.userType:hover{


}
@media only screen and (max-width: 700px) {
    .menuUserLoginName{
        display: none;
    }
}