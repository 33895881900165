

.ProjectPage {
    padding-top: 1em;
}

.form-label{
    color: #005ca1;
    font-weight: bold;
}

.optionContainer{
    color: #021260;
}
.sectionDraggable{
    border: 1px solid #f1f1f1;
    border-bottom: 1px solid #1f66a4;
    border-left: 4px solid #1f66a4;
}
.sectionDraggable.fromParent{
    border: 1px solid #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-left: 2px solid #de4f46;
    border-radius: 0em !important;
}

.componentDraggable.fromParent .componentAccordion {
    border: 1px solid red;
}

.componentAccordion{
    border: 1px solid #f1f1f1;
    border-bottom: 1px solid #1f66a4;
    border-left: 5px solid #1f66a4;
}
.componentAccordion.fromParent{
    border: 1px solid #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-left: 3px solid #de4f46;
    border-radius: 0.2em;
}
.componentDraggable {
    border: 1px solid #1f66a4;
    border-bottom: 2px solid #1f66a4;
    border-left: 5px solid #1f66a4;
    margin-bottom: 0.5em;
    background: #f1f1f1;
    padding: 0.5em 1em;
    font-size: 1.2em;
}
.componentDraggable.fromParent {
    border: 1px solid #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-left: 3px solid #de4f46;
    border-radius: 0.2em;
    padding: 0.2em;
    font-size: 1em;
}


.sectionAccordion .card{
    background: none;
    border: none;
    padding: 0em;
}
.sectionAccordion .card-header{
    border: none;
}
.sectionAccordion .components .card-header{
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    border: none;
    padding: 0.5em 1em;
}
.sectionAccordion .card-body{
    background: none;
    border: none;
    background: blue;
}


.sectionAccordion .sectionAccordionCardheader{
    background: none;
    padding: 0em;
    margin-bottom: 0.5em;
}


.sectionAccordionCardheader h2.closed{
    color: #d1d1d1;
}

.sectionAccordion .card-body{
    border: none;
    padding: 0;
}



.actionButton{
    border: 1px solid #ddd;
    background: #fff;
    margin-left: 0.2em;
}
.actionButton:hover{
    border: 1px solid #ccc;
    background: #fcfcfc;
}

.actionButton.selected{
    background: #005aa6;
    color: white;
}

.componentAccordion .card-body{
    padding: 1em;
    background: #fcfcfc;

}
.componentAccordion{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.componentAccordion.hided,
.componentAccordion.hided .card-header{
    background: #df271b !important;
}
.componentAccordion.hided .card-header h4{
    color: white;
    text-decoration: line-through;
}

.sectionAccordion .sectionAccordionCardBody{
    background: none;
    padding: 0em;
}

.sectionDraggable.hided h2{
    color: white;
    text-decoration: line-through;
}
.sectionDraggable.hided{
    background: #df271b;
}


.sectionsDropZone{
    background: none;
    border: 0px dashed #ccc;
}
.sectionsDropZone.isDraggingOver{
    border: 2px dashed red;
}


.ProjectPage .section .accordion{
    margin-bottom: 1em;
}

.droppableInputComponent{
    background: red;
}

.worksheet{
    background: #fff;
    padding: 1em;
    max-width: 100%;
    margin: 0px auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.worksheetFooter{
    position: fixed !important;
    background: #1f66a4;
    padding: 1em;
    max-width: 100%;
    margin: 0px auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 5;
}

div[data-rbd-draggable-id="textInput"],
div[data-rbd-draggable-id="fileUpload"],
div[data-rbd-draggable-id="multipleChoice"],
div[data-rbd-draggable-id="tableInput"],
div[data-rbd-draggable-id="gefaehrdungsbeurteilung"]{
    background: #021260 !important;
    color: white !important;
}

div[data-rbd-draggable-id="headline"],
div[data-rbd-draggable-id="text"],
div[data-rbd-draggable-id="image"],
div[data-rbd-draggable-id="download"],
div[data-rbd-draggable-id="externalLink"],
div[data-rbd-draggable-id="markdownText"]{
    background: #444444 !important;
    color: white !important;
}

div[data-rbd-draggable-id="ltb"],
div[data-rbd-draggable-id="docuWare"]{
    background: #dc143c !important;
    color: white !important;
}

