.MyProfilePage .pageContent{
    background: #021260 !important;
    padding: 0.5em;
}

.logoutContainer .logout{
    background: #01aef3;
    float: right;
}
.logoutContainer{
    margin-bottom: 1em;
}

.profileBlock table{
    background: #021260;
    color: white;
}