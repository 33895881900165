.pageTitle{
    background: #fff;
    padding-top:0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
}
.pageTitle .col,
.pageTitle .row{
    height:100%;
}
.pageTitle h1{
    color: #005ca1;
}

.topMenuPageLayout{
    background: white;
}

.page-menu{
    border-bottom: 2px solid #005ca1;
}

.page-menu button{
    margin-right: 1em;
}
.page-menu .active button{
    background: #db231f;
    color: white;
}