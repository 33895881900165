table.styledTable .projectRowLink{
    padding: 0.5em 1em;
    display: inline-block;
    width: 100%;
    background: #f5f5f5;
    border: none;
    border-radius: 1em;
}
table.styledTable .projectRowLink.defaultTemplate {
    background: #f5f5f5;
}

table.styledTable .projectRowLink:hover h5{
    color: #f1f1f1;
}
table.styledTable .projectRowLink:hover small{
    color: #f1f1f1;
}
table.styledTable .projectRowLink:hover{
    padding: 0.5em 1em !important;
    background: #11578f !important;
}
table.styledTable .projectRowLink.defaultTemplate:hover {
    background: #680515 !important;
}


table.styledTable .projectRowLink h5{
    font-weight: normal !important;
}

table.styledTable .projectRowLink span.rowTitle,
table.styledTable .projectRowLink span.parentProject,
table.styledTable .projectRowLink span{
    color: #1f66a4;
}

table.styledTable .projectRowLink span.parentProject{
    color: #1f66a4;
    font-size: 0.7em;
}

table.styledTable .projectRowLink:hover span.rowTitle,
table.styledTable .projectRowLink:hover span,
table.styledTable .projectRowLink:hover  span.parentProject{
    color: white;
}

table.styledTable td{
    border: none;
}

table.styledTable .projectRowLink:hover{
    padding: 0.2em 1em;
    display: inline-block;
    width: 100%;
    background: #641723;
}

table.styledTable tr:nth-child(even) td {
    border-left: none;
    background: none;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    color: white;
}
table.styledTable tr td{
    background: none;
    border-left: none;
    box-shadow: none;
    border-radius: 0;
    color: white;

}