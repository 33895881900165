.dropzone{
    border: 3px dashed #df271b;
    padding: 2em;
    background: #F5E8E5FF;
    color: #0d1520;
    border-radius: 1em;
}


.dropzone.disabled{
    background: #ccc;
    border: 0px dashed #df271b;
}



.fileRulesMetaInfo{
    display: inline-block;
    background: #f1f1f1;
    padding: 0.5em 1em;
    margin-right: 1em;
    margin-bottom: 0.5em;
    border-radius: 1em;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.fileRulesMetaInfos{
    display: inline-block;
    padding-top: 0.5em;
    padding-bottom: 0;
    margin-top: 0.5em;
}

.fileuploadFileRow{
    background: #fafafa;
    border: 1px solid #f1f1f1;
    margin-bottom: 1em;
    padding: 0.5em;
    border-radius: 1em;
    padding-bottom: 0;
}