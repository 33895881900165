.componentHeader{
    margin-bottom: 0.5em;
}

.componentHeader .componentHeaderIcons{
    display: inline-block;
    float: right;
}
.componentHeader .componentIcon{
    display: inline-block;
    margin-right: 0.5em;
    padding: 0.5em 1em;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 1em;
}
.componentHeader .componentIcon:nth-last-child(1){
    margin-right: 0em;
}

.worksheetComments{
    margin-top: 1em;
}

.worksheetComments ul{
    margin: 0em;
    padding: 0;
    padding-left: 0em;
    list-style-type: none;
}
.worksheetComments ul li .commentDate{
    margin: 0em;
    border-bottom: 1px solid #ccc2a4;
    padding:0.2em;
    padding-left: 0.5em;
    padding-right: 0.3em;

    background: white;
}
.worksheetComments ul li .commentTime{
    margin: 0em;
    border-right: 1px solid #ccc2a4;
    border-bottom: 1px solid #ccc2a4;
    background: white;
    border-bottom-right-radius: 1em;

    padding:0.2em;
    padding-left: 0.5em;
    padding-right: 0.8em;
}
.worksheetComments .commentText{
    font-style: italic;
    font-weight: bold;
    white-space: pre;
}

.worksheetComments .commentDeleteButton{
    position: absolute;
    right: -1em;
    bottom:-1em;
}

.worksheetComments .commentTimeBox{
    margin-left:1em;
    position:absolute;
    top:-1em;
    left:-2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.worksheetComments ul li {
    margin-bottom: 0.5em;
}
.worksheetComments ul li:last-child {
    margin-bottom: 0em;
}





