.TableInput table{
    width: 100%;
}
.TableInput td{
    width: auto;
}

.TableInput td.small{
    min-width: 80px;
}
.TableInput td.medium{
    min-width: 250px;
}
.TableInput td.big{
    min-width: 400px;
}


.TableInput td input,
.TableInput td select{
    width: 100%;
}




.react-responsive-table table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.react-responsive-table table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

.react-responsive-table table tr {
    border: 1px solid #f1f1f1;
}

.react-responsive-table table th,
.react-responsive-table table td {
    padding: 0.5em;
}



.react-responsive-table .mobile-sort {
    display: none;
}

@media screen and (max-width: 600px) {
    .react-responsive-table table {
        border: 0;
    }

    .react-responsive-table .mobile-sort,
    .react-responsive-table .sort-wrapper {
        display: flex;
        gap: 20px;
    }

    .react-responsive-table table caption {
        font-size: 1.3em;
    }

    .react-responsive-table table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .react-responsive-table table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    .react-responsive-table table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
    }

    .react-responsive-table table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }

    .react-responsive-table table td:last-child {
        border-bottom: 0;
    }
}