@keyframes flyIn {
    from {
        transform: translateY(100%); /* Start außerhalb des Bildschirms von unten */
        opacity: 0;
    }
    to {
        transform: translateY(0); /* Endposition in der sichtbaren Fläche */
        opacity: 1;
    }
}

.toast-container {
    z-index: 1055;
}

.toast {
    background-color: #fff; /* Weißer Hintergrund */
    border-radius: 8px; /* Abgerundete Ecken */
    border-left: 5px solid #007bff; /* Blauer Rand auf der linken Seite */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Weicher Schatten für Tiefe */
    margin-bottom: 10px; /* Abstand zwischen Toasts */
    animation: flyIn 0.5s ease-out;
}

.toast-body {
    padding: 15px 20px; /* Innenabstand für Text */
    font-size: 14px;
    color: #333; /* Dunkelgraue Textfarbe */
}

.toast-header {
    font-weight: bold;
    color: #007bff; /* Blaue Schriftfarbe für den Header */
    padding-bottom: 5px;
}

.toast.show {
    display: block;
}
.toast.error {
    display: block;
    border-radius: 8px; /* Abgerundete Ecken */
    border-left: 5px solid red;

}

.toast-container.position-fixed {
    bottom: 20px; /* 20px Abstand von unten */
    right: 20px; /* 20px Abstand von rechts */
    max-width: 300px; /* Maximale Breite für den Toast */
}