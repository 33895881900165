table.workingSheetCurriculumDetails tr td:first-child{
    padding-right: 1em;
}
table.workingSheetCurriculumDetails tr td:nth-child(2){
    font-weight: bold;
}

table.workingSheetCurriculumDetails tr td{
    text-align: left;
}

.taskDescription span{
    font-style: italic;
}
.taskDescription h2{
    font-style: italic;
    font-weight: bold;
    color: #f1f1f1;
}

.workingSheetCurriculumDetailsWrapper{
    background: white;
    border-radius: 1em;
    padding: 1em;
    display: inline-block;
    max-width: 100%;
}


.sections .section-header h2{
    font-size: 1.8em;
    margin-bottom: 0;
    color: white;
    font-weight: bold;
}

.sections .card h1,
.sections .card h2,
.sections .card h3,
.sections .card h4,
.sections .card h5,
.sections .card h6{
    color: #0d2847;
    font-weight: bold;
}

.sections .card h1{
    font-size: 1.7em;
}
.sections .card h2{
    font-size: 1.6em;
}
.sections .card h3{
    font-size: 1.5em;
}
.sections .card h4{
    font-size: 1.4em;
}
.sections .card h5{
    font-size: 1.3em;
}
.sections .card h6{
    font-size: 1.2em;
}

.sections .section-header{
    background: #df271b;
    border-bottom: none;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.5em;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    margin: 0px 1em;
}



.my-worksheet .section{
    max-width: 100%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-bottom-left-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
    margin-bottom: 1em;
    margin: 0px 1em;
}

.my-worksheet .section-wrapper{
    margin-bottom: 1em;
}

.sectionAccordionCardBody h4.componentHead{
    background: #f1f1f1;
    padding: 0.5em 1em;
}

.my-worksheet .worksheetComponents {
    padding: 1em;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}


.my-worksheet .worksheet-body{
    background: none;
    padding: 0em;
    width: 100%;
    margin: 0px;
    border-radius: 1em !important;
}

.my-worksheet .worksheet-body .sectionDraggable{
    margin-bottom: 1em;
    padding-bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}

.my-worksheet .hiddenSection{
    margin-bottom: 1em;
    padding: 1em;
    border: 3px dashed #fff;
    border-radius: 1em;
}

.my-worksheet .hiddenSection h2{
    display: inline-block;
    margin: 0;
    margin-right: 0.5em;
}

.WorksheetEditorPage .worksheet-button-panel{
    background: #ddd;
    margin-top: 1em;
}

.worksheetView .section-header h2{
    display: inline-block;
    margin-right: 0.5em;
}