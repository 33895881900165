.WorksheetsTable{
    padding-left:1em;
    padding:0.5em;
}

.WorksheetsTable .firstRow{
    padding-left:1em;
    padding:0.5em;
    background: #bdb6b4;
    color:white;
    margin-bottom: 1em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
.worksheetListRow{
    border: 1px solid #ddd !important;
}


.multiEvaluationBody{
    background: #f1f1f1;
    padding: 1em;
    border-radius: 1em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}


.worksheetListRow .SingleSelectInputFormField span{
    color: #021260 !important;
}



.worksheetListRow .changed .SingleSelectInputFormField span{
    color: #fff !important;
}
.worksheetListRow .changed .SingleSelectInputFormField{
    background: #ccc;
    color:#333 !important;
    border-radius: 0.5em;
    font-weight: bold;
}

.worksheetListRow .evaluationMatrix .changed .badge{
    color: #333;
}
.worksheetListRow .evaluationMatrix span{
    text-align: center;
    margin: 0px auto;
    display: block;
}
.worksheetListRow .evaluationMatrix .title{
    text-align: center;
    font-weight: bold;
    display: block;
}

.worksheetListRow .changed .SingleSelectInputFormField span{
    color:#333 !important;
    font-weight: bold;
}
.ellipse {
    white-space: nowrap;
    display:inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.traineeBadge{
    font-weight: normal !important;
    background: #fafafa !important;
    border: none !important;
    color: #11578f;
    margin: 0 !important;
    font-size: 1em;
    line-height: 1em;
    vertical-align: middle;
    padding: 0.5em 1em;
}
.traineeBadge:hover{
    color: #0d1520;
    background: #01aef3 !important;
}


.worksheetListRow .phaseSwitcher .singleSelect{
    min-width: 15em;
}


.pradleAccordion .worksheetListRow.DRAFT_WORKING_SHEET{
    border: 1px solid #333;
}

.pradleAccordion .worksheetListRow.SUBMITTED_WORKING_SHEET{
    border: 1px solid #333;
    background: #81A8D0FF;
}
.pradleAccordion .worksheetListRow.GRADED_WORKING_SHEET{
    border: none;
    background: #f1f1f1;
    box-shadow: none;
}

.pradleAccordion .worksheetListRow.DISCARDED_WORKING_SHEET{
    border: 1px solid #333;
    background: #B49499FF;
}

.pradleAccordion .worksheetListRow.OPEN_WORKING_SHEET {
    border: 1px solid #333;
    background: #fff;

}

.pradleAccordion .worksheetListRow.DRAFT_WORKING_SHEET .traineeBadge,
.pradleAccordion .worksheetListRow.DRAFT_WORKING_SHEET .editedDate,
.pradleAccordion .worksheetListRow.DRAFT_WORKING_SHEET .editedTime,
.pradleAccordion .worksheetListRow.DRAFT_WORKING_SHEET .topic{
    color: #222 !important;
}

.pradleAccordion .card-header{
    background: #021260;
    margin-bottom: 0;
    border-radius: 0;
    border:none;
    padding:0;
}
.pradleAccordion .card-header .cardHeaderWrapper{
    background: #f5f5f5;
    padding-left:1em;
    padding-top:0.5em;
    padding-bottom:0.5em;
    border-radius: 0 !important;
    border-bottom: 1px solid #021260;
}



.pradleAccordion .card-header span.genericBadge{
    background: #021260 !important;
    padding:0.4em !important;
    color: white !important;
}
.pradleAccordion .card-body .topic,
.pradleAccordion .card-body .instructor{
    color: white !important;
    padding: 0.2em 0.5em;
    display: inline-block;
    margin-bottom: 0.5em !important;
}

.pradleAccordion .card-body{
    padding: 1em !important;
}


.pradleAccordion .card-body .WorksheetListStateBadges{
    color: white !important;
    padding: 0.2em 0.5em;
    display: inline-block;
    margin-bottom: 0.5em !important;
    background: #01aef3;
    border-radius: 1em;
}

.pradleAccordion .card-body .novalue{
    color: white !important;
    background: #f5f5f5;
    padding: 0.2em 0.5em;
    display: inline-block;
    margin-bottom: 0.5em !important;
}

.worksheetListForInstructors  .pageContent{
    padding: 0em 0em;
    background: #021260;
    padding-bottom: 2em;
    padding-top: 0em;
    height: 100%;
    min-height: 30vh;
}

.worksheetListForInstructors  .card{
    background: #021260;
}

.pradleAccordion.single .WorksheetListStateBadges {
}

.pradleAccordion .worksheetListRow{
    margin-bottom: 0.5em;
    background: #f1f1f1;
    border-radius: 1em;
    vertical-align: middle;
    border: none;
    padding: 0.5em;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.editedDate{
    color: #0d1520;
}
.editedTime{
    color: #0d1520;
}



.pradleAccordion .card-header span.genericBadge{
    background: #021260 !important;
    padding:0.4em !important;
    color: white !important;
    margin-top:0.5em;
}

.pradleAccordion .card-header h2.open{
    color: #888;
}
.pradleAccordion .card-header h2{
    margin:0;
}

.pradleAccordion .card-header .actionButton:hover {
    border: 1px solid #ccc;
    background: #01aef3;
    color: #021260;
}

.pradleAccordion  .card-body{
    padding:0.5em;
    padding-left: 1.2em;
    border:0px;
    padding-right: 1.2em;
}

.pradleAccordion .card-body button{
}
.pradleAccordion .card-body button:hover{
    background: #222;
}
.pradleAccordion .card{
    border:none;
}
