.pageHeader .form-select{
    color:#005ca1;
    border-color: #01aef3;
}

.pageHeader .optionTitle{
    color: #005ca1;
}

.pageHeader .myselect div{
    width: 100% !important;
}

.pageHeader .paginationNumber{
    color: #005ca1;
    font-size: 1.2em;
}

.pageHeader .singleSelect{
    border: 0px !important;

}
.pageHeader h1{
    margin-top:0.2em;
}
.pageHeader .multiselect-container{
    border: 1px solid #01aef3 !important;
    text-align: left;
}
.pageHeader .singleSelect span{
}