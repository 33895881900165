model-viewer {
    cursor: grab;
    display: flex;
    height: 40vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
}
#button-load {

    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 6% 50%;
    background-color: #000;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 18px 9px 40px;
    font-weight: 500;
    box-shadow: 0 0 8px rgba(0,0,0,.2), 0 0 4px rgba(0,0,0,.25);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 100;
}

.button-span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
    box-sizing: border-box;
}
