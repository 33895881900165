
@page {
    size: 210mm 297mm;
    font-size: 0.5em !important;
}



.sections-print .section-print-header{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #df271b;
    padding: 1em 2em;
}
.sections-print .section-print-header h2{
    font-size: 2em;
    margin-bottom: 0;
    color: white;
    font-weight: bold;
    padding: 0em;
}

.sections-print .card h1,
.sections-print .card h2,
.sections-print .card h3,
.sections-print .card h4,
.sections-print .card h5,
.sections-print .card h6{
    color: #0d2847;
    font-weight: bold;
}

.sections-print .card h1{
    font-size: 1.7em;
}
.sections-print .card h2{
    font-size: 1.6em;
}
.sections-print .card h3{
    font-size: 1.5em;
}
.sections-print .card h4{
    font-size: 1.4em;
}
.sections-print .card h5{
    font-size: 1.3em;
}
.sections-print .card h6{
    font-size: 1.2em;
}





.my-worksheet-print .section-print{
    max-width: 100%;
    background: #fff;
}

.components-print h4{
    padding: 1em;
    max-width: 100%;
    color: #333;
    background: #f1f1f1;
    margin-bottom: 1em;
    font-weight: bold;
    margin-bottom: 0;
}

.my-worksheet-print .worksheetComponents {
    padding: 1em;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}


.my-worksheet-print .worksheet-body{
    background: none;
    padding: 0em;
    width: 100%;
    margin: 0px;
    border-radius: 1em !important;
}

.my-worksheet-print .worksheet-body .sectionDraggable{
    margin-bottom: 1em;
    padding-bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1em !important;
}

.my-worksheet-print .hiddenSection{
    margin-bottom: 1em;
    padding: 1em;
    border: 3px dashed #fff;
    border-radius: 1em;
}

.my-worksheet-print .hiddenSection h2{
    display: inline-block;
    margin: 0;
    margin-right: 0.5em;
}

.WorksheetEditorPage .worksheet-button-panel{
    background: #ddd;
    margin-top: 1em;
}

.worksheetView .section-print-header h2{
    display: inline-block;
    margin-right: 0.5em;
}

.componentBody{
    padding: 1em;
}

.ComponentFooter{
    margin-left: 1em;
}

.EvaluationResults{
    margin-top: 1em;
    background: #0a375c;
    color: #f1f1f1;
}
.EvaluationResults td,
.EvaluationResults .statusBadge span{
    color: #f1f1f1 !important;
}