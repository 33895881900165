.courseKachel .kachelHeader{
    background: #1f66a4 !important;
    color:white;
    border-bottom: 2px solid #021260;
    padding:0.5em 1em;
    border-radius: 1em;
    border-bottom-left-radius: 0em;
    border-bottom-right-radius: 0em;
    font-size: 1.3em;
}

.courseKachelWrapper{
    margin-top:1em;
}
.courseKachelWrapper:nth-child(1){
    margin-top:0em;
}
.courseKachel{
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.courseKachel a{
    text-decoration: none;
}

.courseKachel  a:hover{
    color:white;
}

.courseKachel  a:hover .kachelHeader{
    background: #021260 !important;
}



.courseKachel .kachelContent{
    background: #f1f1f1 !important;
    padding: 1em;
}

.courseKachel .kachelFooter{
    border-top: 2px solid #df271b !important;
    background: #fff;
    padding: 1em;
    border-radius: 1em;
    border-top-left-radius: 0em;
    border-top-right-radius: 0em;
}


.myProgressBar {
    margin-bottom: 0em !important;
}

@media (max-width: 968px) {
    /* put your css styles in here */

    .kwFilter .desktopOnly{
        display: none;
    }
}


