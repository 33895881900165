.componentTopSettings{

}

#multiselectContainerReact,
select, option{
    cursor: pointer !important;
}

.componentTitleInputField {
    display: inline-block;
    width: calc(100% - 3em);
    border: none;
    color: #021260;
    margin-left: 0.5em;
    padding:0.5em;
    font-size: 0.8em;
}

h4.componentTitle.hidden input{
    color: #641723;
    text-decoration: line-through;
}


.hiddenButton{
    background: none;
    color: #021260;
    border: none;
    display: inline;
    margin-left: 0.5em;
}
.hiddenButton:hover,
.hiddenButton:active,
.hiddenButton:focus{
    background: #f1f1f1;
    color: #021260;
    border: none;
}
.componentTitleInputField{
    width: calc(100% - 5em);
}
.componentTitleInputField:focus {
    background: #f1f1f1;
    color:#021260;
}
.componentTitleInputField:hover {
    background: #f1f1f1;
    color:#021260;
}

.componentsDropZone .mdxeditor-root-contenteditable > div{
    padding: 1em !important;
}

.componentsDropZone .mdxeditor-root-contenteditable > div:focus{
    background: #f1f1f1;
}