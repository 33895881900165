.WorksheetEditorPage .worksheet-title-image.viewmode-1{
    width: 100%;
    margin-bottom: 0.5em;
    border: 4px dashed #f1f1f1;
}

.WorksheetEditorPage .worksheet-title-image.viewmode-1 img{
    background: #fff;
    width: 100%;
}

.WorksheetEditorPage .worksheet-scroll-panel{
    width: 100%;
}

.WorksheetEditorPage .worksheet-scroll-panel-inner{
    width: 100%;
}

.WorksheetEditorPage .imageNavigation{
    display: inline-block;
    background: white;
    position: absolute;
    border-radius: 0.5em;
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: -3em;
    margin-left: 0.2em;
    z-index: 2;
}

.stickytitleimage{
    position: relative;
}

@media (min-width: 768px) {
    .stickytitleimage.--stuck{
        position: fixed;
        top: 0;
        left: 0;
        border: #641723;
        max-width: 100%;
        z-index: 1;
    }
}



.WorksheetEditorPage .imageNavigation.sticky{
    position: fixed;
    bottom: 0.2em;
    left: 0.2em;
}

.WorksheetEditorPage .imageNavigation img{
    border: 3px dashed #f1f1f1;
    border-radius: 0.5em;
    margin-bottom: 0.2em;
}
.WorksheetEditorPage .imageNavigation .btn{
    margin-right: 0.2em;
}
.WorksheetEditorPage .imageNavigation .btn:last-child{
    margin-right: 0em;
}

.WorksheetEditorPage .image-maximized-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0d1520;
}
.WorksheetEditorPage .image-maximized-container .image-container{
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: white;
}