@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/violet.css";

#root,html,body{

}


.mdxeditor-root-contenteditable > div{
    padding: 0em !important;
}

.disclaimer{
    background: #11578f;
    padding: 1em;
}

.bg-dark {
    background-color: #d1d1d1 !important;
    color: #0d1520;
}


.btn.btn-info{
    color: white;
}
.btn:hover{
    border: 1px solid white;
}

@font-face {
    font-family: roboto-regular;
    src: url('/public/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
    font-family: roboto-bold;
    src: url('/public/fonts/RobotoCondensed-Bold.ttf');
}

body {
    font-family: roboto-regular, Tahoma;
    color: #6d7680;
}

b{
    font-family: roboto-bold, Tahoma;
}

h1,h2,h3,h4,h5 {
    font-family: roboto-regular, Tahoma;
    color: #005ca1;
}


.multiselect-container .singleChip{
    background: #021260;
    color: #fff;
}


.timeBadge{
    border:1px solid #ddd !important;
    background: white !important;
    color: #021260;
    width: 100%;
}
.dateBadge{
    border:1px solid #ddd !important;
    background: white !important;
    color: #021260;
    width: 100%;
}

.bausteineBadge{
    width: 100%;
    background: #021260 !important;
    color: white !important;
}



@media all and (min-width:768px) {
    .SingleTraineePage .pageContent,
    .SingleInstructorPage .pageContent,
    .WorksheetsListPageForInstructor .pageContent,
    .WorksheetsListPageForTrainee .pageContent,
    .MyProfilePage .pageContent,
    .SingleCoursePage .pageContent{
        padding: 2em 5em 5em 5em !important;
    }
}




/* reset */
button {
    all: unset;
}

.ToastViewport {
    --viewport-padding: 25px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: 10px;
    width: 390px;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
}

.ToastRoot {
    background-color: white;
    border-radius: 6px;
    box-shadow:
            hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
            hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    padding: 15px;
    display: grid;
    grid-template-areas: "title action" "description action";
    grid-template-columns: auto max-content;
    column-gap: 15px;
    align-items: center;
}
.ToastRoot[data-state="open"] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state="closed"] {
    animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe="end"] {
    animation: swipeOut 100ms ease-out;
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
}

.ToastTitle {
    grid-area: title;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--slate-12);
    font-size: 15px;
}

.ToastDescription {
    grid-area: description;
    margin: 0;
    color: var(--slate-11);
    font-size: 13px;
    line-height: 1.3;
}

.ToastAction {
    grid-area: action;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 500;
    user-select: none;
}
.Button.small {
    font-size: 12px;
    padding: 0 10px;
    line-height: 25px;
    height: 25px;
}
.Button.large {
    font-size: 15px;
    padding: 0 15px;
    line-height: 35px;
    height: 35px;
}
.Button.violet {
    background-color: white;
    color: var(--violet-11);
    box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
    background-color: var(--mauve-3);
}
.Button.violet:focus {
    box-shadow: 0 0 0 2px black;
}
.Button.green {
    background-color: var(--green-2);
    color: var(--green-11);
    box-shadow: inset 0 0 0 1px var(--green-7);
}
.Button.green:hover {
    box-shadow: inset 0 0 0 1px var(--green-8);
}
.Button.green:focus {
    box-shadow: 0 0 0 2px var(--green-8);
}


.taskDescription .alert{
    background: #11578f;
    border-radius: 0;
    padding: 0.5em 0.5em;
    color: #f1f1f1;
}

.taskDescription .alert .workingSheetCurriculumDetailsWrapper{
    color: #0d2847;
}

.courseListAccordions .cardHeaderWrapper{
    background: #f1f1f1 !important;
    color: #0d2847 !important;
}
.courseListAccordions .cardHeaderWrapper h2{
    color: #0d2847 !important;
}

.pradleAccordion .card-header .cardHeaderWrapper:hover{
    background: #f1f1f1;
}

.EvaluationResultsTable{
    color: #0d2847;
}

/* give transparent images white background */
.pswp__img,.pswp-gallery img{
    background: white;
}


.dynamicMediaGallery  img{
    max-height: 60em;
    width: auto;
    max-width: 100%;
}

.image-maximized-container{
    overflow-y:scroll;
}

.image-maximized-container .dynamicMediaGallery{
    padding-bottom: 2.5em;
}

.dynamicMediaGallery .imageWrapper img{
    max-height: 60em;
    width: auto;
    max-width: 100%;
}
.dynamicMediaGallery .imageWrapperOuter.small img{
    max-height: 15em;
    width: auto;
    max-width: 100%;
}

.dynamicMediaGallery .imageWrapperOuter {
    max-width: 100%;
    display: inline-block;
    margin-right: 1%;
    float: left;
    margin-bottom: 1em;
}




.dynamicMediaGallery .imageWrapperOuter,
.dynamicMediaGallery .pswp-gallery .imageWrapper{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background: #fff;
    padding: 0.5em;
    display: inline-block;
    text-align: center;
    border-radius: 0.3em;
}
.dynamicMediaGallery .imageWrapper{
    background: #f1f1f1;
    padding: 0.1em;

    display: inline-block;
    text-align: center;
    border-radius: 0.3em;
    overflow: hidden;
}

.dynamicMediaGallery  .imageWrapper img{
    background: #fff;
}

.dynamicMediaGallery .imageWrapperOuter .badge{
    background: #11578f !important;
    color: white;
}

.dynamicMediaGallery .imageWrapperOuter span{
    font-style: italic;
}
