.GefaerdungsbeurteilungInput table{
    width: 100%;
}
.GefaerdungsbeurteilungInput td{
    width: auto;
}

.GefaerdungsbeurteilungInput td.small{
    min-width: 80px;
}
.GefaerdungsbeurteilungInput td.medium{
    min-width: 250px;
}
.GefaerdungsbeurteilungInput td.big{
    min-width: 400px;
}


.GefaerdungsbeurteilungInput td input,
.GefaerdungsbeurteilungInput td select{
    width: 100%;
}



@media (min-width: 768px) {
    .GefaerdungsbeurteilungInput .inputFieldsRow label.form-label,
    .GefaerdungsbeurteilungInput .inputFieldsRow small.form-text{
        display: none;
    }
}

.GefaerdungsbeurteilungInput .inputFieldsRow{
    margin-bottom: 1em;
    border-radius: 1em;
}





.inputFieldsRow small.form-text {
    color: #7189ff;
}

@media (max-width: 768px) {
    /* put your css styles in here */
    .GefaerdungsbeurteilungInput .tableHeaderRow {
        display: none;
    }

    .GefaerdungsbeurteilungInput .inputFieldsRow .SingleSelectInputFormField{
        margin-bottom: 1em;
    }

    .GefaerdungsbeurteilungInput .inputFieldsRow{
        margin-bottom: 1em;
        border-radius: 0em;
        padding-bottom: 1em;
        border-bottom: 2px solid #021260;
    }

}

.GefaerdungsbeurteilungInput .inputFieldsRow input.form-control {

}

.GefaerdungsbeurteilungInput-Row{
    margin-bottom: 0.5em;
    padding: 0.5em;
    padding-bottom: 0;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 0.3em;
}

.GefaerdungsbeurteilungInput .alert{
    display: inline-block;
    padding: 0.2em 0.5em;
    margin: 0;
    margin-right: 1em;
    margin-bottom: 0.5em;
    font-size: 0.75em !important;
}



.GefaerdungsbeurteilungInput-Header{

}

.GefaerdungsbeurteilungInput-Header img{
    border-radius: 100%;
    width: 3em;
    height: 3em;
}