.LogoutButtonComponent button.logout{
    padding: 0.2em 0.5em;
    margin: 0;
    background: #fff;
    color: #1f66a4;
    border: #6d7680;
}
.LogoutButtonComponent button.logout:hover{
    background: #ddd;
    color: #1f66a4;
    border: #6d7680;
}
