.phaseStateInfoBoxList li{
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1em;
}

.phaseStateInfoBoxList{
    margin-bottom: 0em;
}

.phaseStateInfoBoxList li .title{
    background: #ddd;
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 1em;
    color: #0d2847;
}

.phaseStateInfoBoxList li.finish-state .title{
    background: #0d3c48;
    display: inline-block;
    color: white;
    padding: 0.5em 1em;
    border-radius: 1em;
}

.phaseStateInfoBoxList li.selected .title{
    background: #01aef3;
    color: white;
    font-weight: bold;
}