table.EvaluationResultsTable tr td:first-child{
    padding-right: 1em;
}
table.EvaluationResultsTable tr td:nth-child(2){
    font-weight: bold;
}

.EvaluationResults{
    background: white;
    border-radius: 1em;
    padding: 1em;
    display: inline-block;
    max-width: 100%;
}